.container {
  background: linear-gradient(to bottom,
      #0032fb 0%,
      #0032fb calc(25rem + 1%),
      #FAFAFA calc(25rem + 1%),
      #fff 100%);
  min-height: 100%;
  width: 100%;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background: #fff;
  max-width: 80rem;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 2.5rem;
  position: relative;
  min-height: 250px;
  margin-bottom: 30px;
}

.logout {
  position: absolute;
  top: 0px;
  right: 10px;
  color: white;
  vertical-align: middle;
  cursor: pointer;
  background-color: #0966fa;
  padding: 10px 15px 10px 10px;
  border-radius: 8px;

  & img {
    vertical-align: bottom;
  }
}

.logout:hover {
  background-color: #2879fc !important;
}

@media only screen and (max-width: 580px) {
  .box {
    max-width: 42rem;
    padding: 3rem 2rem;
    margin: 0px auto;
  }

  .logout {
    top: -7px;
    padding: 4px 9px 5px 1px;
    font-size: smaller;
  }
}