.container {
  background: linear-gradient(to bottom,
      #0032fb 0%,
      #0032fb calc(25rem + 1%),
      #FAFAFA calc(25rem + 1%),
      #fff 100%);
  min-height: 100%;
  width: 100%;
}

.boxLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background: #fff;
  max-width: 40rem;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 3rem 5rem 3rem 5rem;
  position: relative;
}

@media only screen and (max-width: 580px) {
  .box {
    max-width: 42rem;
    padding: 3rem 2rem;
  }
}