.input {
  width: 100%;
  margin-top: 0.5rem;
}

.ctaButton {
  width: 70%;
  margin: 0 auto;
  font-size: large;
}

@media only screen and (max-width: 580px) {
  .box {
    max-width: 35rem;
  }
}