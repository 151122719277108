.movRow {
  display: inline-flex;
  width: 100%;
}

.movHour {
  color: #888888;
}

.badge,
.badgeOther,
.badgeConsumo,
.badgeConsumoFail,
.badgeDescarga,
.badgeCarga {
  padding: 3px;
  border-radius: 5px;
  background-color: #f2e7d7;
  text-align: center;
  height: 26px;
  display: inline-table;
  width: 100%;
}

.badgeOther {
  background-color: #ebe7f0;
  color: #82698d;
}

.badgeConsumo {
  background-color: #d7e7f2;
  color: #2951cf;
}

.ConsumoFail {
  background-color: #fff7ad;
  color: #5f2600;
}

.badgeDescarga {
  background-color: #fce8e8;
  color: #d8603b;
}

.badgeCarga {
  background-color: #e8fcf1;
  color: #39b52d;
}

.movRowLine {
  display: flex;

}

.movRow {
  display: grid;
  align-items: center;
  margin: 0.5rem 0;
  width: 100%;
  text-align: center;
  padding: 0.8rem;
  border-bottom: 1px solid #c1c1c1;
  font-size: inherit;

  & div {
    align-items: center;
    margin: 0 0.5rem;
  }

  &__cardMovs_A {
    width: 15%;
  }

  &__cardMovs_B {
    width: 25%;
    font-size: larger;
    background-color: #fdfdfd;
    border-radius: 5px;
    padding: 1px;
  }

  &__cardMovs_C {
    width: 17%;


    & img {
      position: relative;
      top: 2px;
    }
  }

  &__cardMovs_D {
    width: 42%;
  }

  &__cardMovs_DXS {
    display: none;
  }
}

@media only screen and (max-width: 580px) {
  .movRowLine {
    display: inline-flex;
    width: auto;
  }

  .movRow {
    font-size: small;
    padding: 0.3rem;
    overflow: hidden;

    &__cardMovs_A {
      font-size: smaller;
      white-space: nowrap;
      min-width: 15%;
    }

    &__cardMovs_B {
      font-size: inherit;
      white-space: nowrap;
      min-width: 40%;
      display: grid;
    }

    &__cardMovs_C {
      min-width: 35%;
      display: inline-grid;
    }

    &__cardMovs_DXS {
      display: block;
      text-align: initial;
      padding: 3px 0px;
    }

    &__cardMovs_D {
      display: none;
    }
  }
}