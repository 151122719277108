.main {
  position: relative;
}

.blur {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

.noVisible {
  display: none;
}

.visible {
  display: flex;
}

.memo {
  background-color: #f0e3fb;
  padding: 0px 5px;
  border-radius: 3px;
  cursor: pointer;
  transition: transform 0.25s ease;
}

.memo:active {
  transform: scale(1.2);
}

.modal {
  z-index: 999;
  background: rgb(125 125 125 / 30%);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.5s;

  &__content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    width: 65rem;
    border-radius: 1rem;
    min-height: 250px;
    flex-direction: column;
    display: flex;
    box-shadow: 4px 3px 8px 1px #a09c9c;
    text-align: center;
  }

  &__header {
    padding: 0rem 1rem;
    text-align: left;
    width: 100%;
    font-weight: 600;
    font-size: 1.8rem;
    text-align: center;

    &__close {
      float: right;
      cursor: pointer;
    }
  }

  &__body {
    padding: 1rem;
    padding-bottom: 2rem;
    text-align: center;
    font-size: 1.6rem;
    width: 80%;
    margin: 0 auto;
  }

  &__qr {
    text-align: center;
    align-items: center;
    width: 100%;
    display: inline-flex;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  &__simulate {
    text-align: center;
    align-items: center;
    width: 100%;
    margin-top: 25px;
  }

  &__footer {
    width: 100%;
    margin-top: auto;
  }
}

.coin {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;

  &__field,
  &__fieldH {
    width: 100%;
    background: #e9e9ec;
    margin: 1rem 0rem;
    padding: 2.5rem 1rem 1rem 1rem;
    border-radius: 0.5rem;
    align-items: center;
    display: flex;
    position: relative;
    font-size: 1.4rem;

    & span {
      position: absolute;
      top: 5px;
      font-size: small;
    }
  }

  &__fieldH {
    background: #f0e3fb;
  }
}

.icon__copy {
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  cursor: pointer;
  transition: transform 0.25s ease;

}

.icon__copy:active {
  transform: scale(2);
}



@media only screen and (max-width: 580px) {
  .modal {

    &__content {
      padding: 10px;
      width: 98%;
    }

    &__body {
      padding: 0.4rem;
      text-align: center;
      font-size: 1.5rem;
      width: 100%;
      margin: 0 auto;
    }
  }
}