.input {
  width: 100%;
  margin-top: 0.5rem;
}


@media only screen and (max-width: 580px) {
  .box {
    max-width: 35rem;
  }
}