// Colors
$label-colour: #ef7c7c;
$disabled-switch-colour: #f3eded;
$disabled-colour: #ddd;
$toggle-colour: #3aa555;
$white: #fff;
$focus-color: #ff0;

.toggle_switch {
  position: relative;
  margin-top: 2px !important;
  margin-right: 10px;
  width: 115px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  cursor: pointer;

  &.small_switch {
    width: 40px;

    .toggle_switch_inner {

      &:after,
      &:before {
        content: "";
        height: 20px;
        line-height: 20px;
      }
    }

    .toggle_switch_switch {
      width: 16px;
      right: 20px;
      margin: 2px;
    }
  }

  &_checkbox {
    display: none;
  }

  &_label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid $label-colour;
    border-radius: 20px;
    margin: 0;

    &:focus {
      outline: none;

      >span {
        box-shadow: 0 0 2px 5px red;
      }
    }

    >span:focus {
      outline: none;
    }
  }

  &_inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;

    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 30px;
      padding: 0;
      line-height: 30px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }

    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 13px;
      background-color: $toggle-colour;
      color: $white;
    }
  }

  &_disabled {
    background-color: $disabled-colour !important;
    cursor: progress;

    &:before {
      background-color: $disabled-colour !important;
      cursor: progress;
    }

    &:after {
      background-color: $disabled-colour !important;
      cursor: progress;
    }
  }

  &_inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: $label-colour;
    color: $white;
    text-align: right;
  }

  &_switch {
    display: block;
    width: 20px;
    margin: 5px;
    background: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 85px;
    border: 0 solid $label-colour;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;

    &_disabledSwitch {
      background-color: $disabled-switch-colour !important;
      cursor: progress;
    }
  }

  &_checkbox:checked+&_label {
    .toggle_switch_inner {
      margin-left: 0;
    }

    .toggle_switch_switch {
      right: 0px;
    }
  }

  &.small_switch {
    width: 40px;

    .toggle_switch_inner {

      &:after,
      &:before {
        content: "";
        height: 20px;
        line-height: 20px;
      }
    }

    .toggle_switch_switch {
      width: 16px;
      right: 20px;
      margin: 2px;
    }
  }
}