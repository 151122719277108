@use 'src/styles/variables' as *;

.card {
  position: relative;
  width: 400px;

  &_number {
    position: absolute;
    bottom: 0.6em;
    line-height: 3rem;
    width: 100%;
    padding: 0px 15px;
    color: white;
    text-align: end;

    &>span {
      font-size: 13px;
    }
  }
}

.cardBox {
  width: 100%;
  display: flex;
  min-height: 270px;
  vertical-align: middle;
}

.cardImageDiv,
.cardImageDivBack {



  & button {
    width: 100%;
    z-index: -1;
  }

  &_moreinfo {
    background-color: #3f3f3f;
    width: fit-content;
    border-radius: 4px;
    padding: 3px 8px;
    color: #f2f2f2;
    border: 2px solid #656565;
    font-size: 10px;
    line-height: normal;
    position: absolute;
    bottom: 4px;
    right: 15px;
    cursor: pointer;
  }
}

.cardImageDivBack {
  &_moreinfo {
    bottom: 99px;
    cursor: pointer;
  }
}

.cardImage {
  display: flex;
  padding: 0px;
  width: 100%;
  flex-direction: column;
  position: relative;
}

@media only screen and (max-width: 580px) {
  .card {
    &_number {
      bottom: 0.7em;
    }
  }

  .cardBox {
    display: block;
  }

  .cardImage {
    width: 100%;
    position: relative;
    width: 420px;
    left: -20px;

    & button {
      top: 10px;
      left: 5%;
      width: 90%;
      z-index: 1;
    }
  }

  .cardImageDivBack {

    &_moreinfo {
      bottom: 100px;
      cursor: pointer;
    }
  }

  .cardImageDiv {

    &_moreinfo {
      bottom: 3px;
      cursor: pointer;
    }
  }


}