@use 'src/styles/variables' as *;

.cardMovs {
  width: 100%;
  position: relative;
  min-height: 100px;
}

.movNoMov {
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  height: 50px;
  color: #c1c1c1;
  font-size: large;
}

.movRowH {
  display: flex;
  align-items: center;
  padding: 0.8rem;
  width: 100%;
  text-align: center;
  background-color: #F8F8FB;
  font-weight: 600;
  border-bottom: 1px solid #c1c1c1;

  & div {
    align-items: center;
    margin: 0 0.5rem;
  }

  &__cardMovs_A {
    width: 15%;
  }

  &__cardMovs_B {
    width: 25%;
  }

  &__cardMovs_C {
    width: 17%;
  }

  &__cardMovs_D {
    width: 42%;
  }
}

.cardLoadBtn {
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 12px 12px 12px;
  position: absolute;
  top: -15px;
  width: 100%;
  height: 55px;
  color: #06A96E;
  background: #D7F2E8;
  border-radius: 0px 0px 12px 12px;
  z-index: 1;
}

.cardFeedbackBtn {
  height: 55px;
  color: white;
  background: #63a1ff;
  font-weight: 700;
  margin-bottom: 10px;
}

.cardBox {
  width: 100%;
  display: flex;
  min-height: 270px;
  vertical-align: middle;
  z-index: 2;
}

.cardTitle {
  width: 50%;
}

.cardTitle_HI {
  width: 100%;
}

.cardImage {
  display: flex;
  padding: 0px;
  width: 50%;
  flex-direction: column;
  position: relative;
  z-index: 2;

  &>img {
    z-index: 1;
  }
}

.Asset {
  margin-top: 0px !important;
  margin-left: 5px;
  margin-right: 4px;
  min-width: 22px;
  text-align: center;
  align-items: center;
  display: inline-grid;
}

.cardBalance {
  display: none;
}

.cardBalanceLG {
  display: block;

  & img {
    margin-top: 8px;
  }

  & div>div {
    margin-top: 9px;
    font-size: 2rem;
  }
}

@media only screen and (max-width: 580px) {
  .card {
    &_number {
      bottom: 10.8em;
    }
  }

  .cardBox {
    display: block;
  }

  .cardBalanceLG {
    display: none;
  }

  .cardBalance {
    display: block;
    width: 100%;
    padding: 2rem 0rem;
  }

  .cardTitle_HI {
    text-align: center;
  }

  .cardImage {
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .cardLoadBtn {
    padding: 12px 12px 12px 2px;
    position: relative;
    top: -10px;
    height: 51px;
    z-index: 1;
    margin: 10px 0px 12px 0px;
    border-radius: 0.7rem;
  }

  .cardTitle {
    width: 100%;
  }

  .movRowH {
    &__cardMovs_A {
      min-width: 15%;
    }

    &__cardMovs_B {
      min-width: 40%;
    }

    &__cardMovs_C {
      min-width: 35%;
    }

    &__cardMovs_D {
      display: none;
    }
  }
}

.error {
  color: #f20;
  width: 100%;
  text-align: right;
  margin-right: 0.5rem;
}