.loading {
  width: 100%;
  display: grid;
  place-content: center;
}


.spin {
  position: absolute;
  place-content: center;
  left: 50%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #4f4f4f;
  border-radius: 50%;
  content: "";
  height: 35px;
  width: 35px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  will-change: transform;
}