.container {
  place-content: center;
  display: inline-block;
  position: relative;
  width: 100%;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.icon {
  fill: #fff;
}

.button {
  min-height: 45px;
  padding: 0.5rem 1.2rem;
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
  border: none;
  cursor: pointer;
  font-size: 1.7rem;
}

.disabled {
  background: #97a8f4;
  cursor: not-allowed;
}


@media only screen and (max-width: 1290px) {
  .button {
    font-size: 1.4rem;
  }
}