.header {
  display: flex;
  width: 100%;
  place-content: center center;
  place-items: center center;
  min-height: 15rem;
}

@media only screen and (max-width: 580px) {
  .header {
    min-height: 13rem;
  }

}